import React from "react";
import styled from "styled-components";
import Seo from "../components/layout/Seo";
import {md, xs, xxs} from "../styles/utils/media_queries";
import {graphql, useStaticQuery} from "gatsby";
import Link from "gatsby-link";

const Hero = styled.div`
    background: var(--darkpurple);
    text-align: center;
    color: white;
    padding: 7% 10%;
    margin-bottom: 3%;
    *{
      text-transform:uppercase;
      font-family: "Integral CF";
    }
  h1{
    margin: 0;
    font-size: 24px;
    font-family: "Integral CF Bold";
    ${xxs(`
        font-size: 32px;
    `)}
    ${xs(`
        font-size: 48px;
    `)}
    ${md(`
        font-size: 64px;
    `)}
  }
`;
const Wrapper = styled.div`
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10%;
  .content{
    font-size: 14px;
    margin-bottom: 20px;
    ${xs(`
        font-size: 16px;
        margin-bottom: 30px;
    `)}
    ${md(`
        font-size: 24px;
        margin-bottom: 40px;
    `)}
  }
`;

export default function ThankYou(){
    const data = useStaticQuery(graphql`
    {
      allWpPage(filter: {id: {eq: "cG9zdDoxNjIy"}}) {
        nodes {
          thankYou {
            thankYouContent
            thankYouTitle
            thankYouButton {
              title
              url
            }
          }
        }
      }
    }
  `)
    // const seoData = data?.allWpPage?.nodes[0]?.template?.seoMetaTags
    // const seo = {
    //     title: seoData?.seoMetaTagsTitle,
    //     description: seoData?.seoMetaTagsDescription
    // }

    const title = data?.allWpPage?.nodes[0]?.thankYou?.thankYouTitle;
    const content = data?.allWpPage?.nodes[0]?.thankYou?.thankYouContent;
    const button = data?.allWpPage?.nodes[0]?.thankYou?.thankYouButton;
    return(
        <>
            <Seo title={"Thank you"} />
            <div className="container">
                <Hero>
                    <h1 dangerouslySetInnerHTML={{ __html: title }}/>
                </Hero>
                <Wrapper>
                    <div className="content">
                        <p>{content}</p>
                    </div>
                    <Link className="main_button" to={button?.url}>{button?.title}</Link>
                </Wrapper>
            </div>
        </>
    )
}